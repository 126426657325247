const AUTH_CHANGE_PASSWORD_SCHEMA = schema
  .object({
    password: schema
      .string({
        required_error: localeRuValidate.required,
      })
      .min(8, {
        message: localeStringReplacement(localeRuValidate.lengthMin, {
          items: "символов",
          length: "8",
        }),
      }),
    password_confirmed: schema
      .string({
        required_error: localeRuValidate.required,
      })
      .min(8, {
        message: localeStringReplacement(localeRuValidate.lengthMin, {
          items: "символов",
          length: "8",
        }),
      }),
  })
  .superRefine(({ password, password_confirmed }, { addIssue }) => {
    if (password !== password_confirmed) {
      addIssue({
        code: "custom",
        message: localeStringReplacement(localeRuValidate.fieldsMatch, {
          fields: "Пароли",
        }),
        path: ["password_confirmed"],
      });
    }
  });

type AuthChangePasswordSchemaInfer = SchemaInfer<typeof AUTH_CHANGE_PASSWORD_SCHEMA>;

type AuthChangePasswordFields = FormFields<AuthChangePasswordSchemaInfer>;

type AuthChangePasswordForm = ChangePasswordPayload;

export { AUTH_CHANGE_PASSWORD_SCHEMA };
export type { AuthChangePasswordFields, AuthChangePasswordForm };
