const useAuthChangePasswordForm = () => {
  const { openModal } = useModalStore();
  const { openNotification } = useNotificationStore();
  const route = useRoute();
  const loading = ref(false);

  const { defineField, errors, handleSubmit, setErrors } = useForm<AuthChangePasswordForm>({
    validationSchema: toTypedSchema(AUTH_CHANGE_PASSWORD_SCHEMA),
  });

  const fields: AuthChangePasswordFields = {
    password: defineField("password"),
    password_confirmed: defineField("password_confirmed"),
  };

  const submit = handleSubmit(async (formData) => {
    formData.token = route.query.token as string;
    loading.value = true;

    const { body, success } = await useAuthChangePasswordDto(formData);

    if (success) {
      openNotification("Пароль сохранен! Теперь вы можете входить с его помощью в свой аккаунт");
      openModal(SHARED_MODAL.AuthLogin);
    }

    if (!success) {
      setErrorsFields.rest(setErrors, body);
    }

    loading.value = false;
  });

  return {
    errors,
    fields,
    handleSubmit: submit,
    loading,
  };
};

export { useAuthChangePasswordForm };
