const useAuthChangePasswordDto = async (payload: ChangePasswordPayload) => {
  const response = await useFetchData<FetchData>("/auth/change-password", {
    body: payload,
    method: "POST",
  });

  return {
    ...response,
  };
};

export { useAuthChangePasswordDto };
